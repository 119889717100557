import Header from 'components/Header';
import React from 'react';
import Hero from 'components/sections/Project/Hero';
import Main from 'components/sections/Project/Main';
import { ProjectProps } from 'components/types';
import Contact from 'components/Contact';
import Footer from 'components/Footer';
import Next from 'components/sections/Project/Next';
import ContentProject from 'content/types';

const getNextProjectInfo = (index: number, projects: ContentProject[]) => {
  if (index === projects.length - 1)
    return { name: projects[0].name, desc: projects[0].quickDesc };
  return {
    name: projects[index + 1].name,
    desc: projects[index + 1].quickDesc,
  };
};

const Project: React.FC<ProjectProps> = ({ white = false, content }) => {
  const { projects, project, index } = content;

  const next = getNextProjectInfo(index, projects);

  return (
    <>
      <Header content={projects} />
      <Hero content={project} />
      <Main content={{ project: project, next: next }} />
      <Next content={next} />
      <Contact />
      <Footer content={projects} />
    </>
  );
};

export default Project;
