import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';

const Wrapper = styled.ul`
  display: block;
  position: relative;
  perspective: 700px;

  li {
    transform-origin: 0% 50% 0;
    transform-style: preserve-3d;

    img {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }

  @media ${device.tablet} {
    width: 85px;
    height: 125px;
  }
`;

const Page = styled.li`
  position: absolute;
  transition: transform 0.3s;

  &:nth-of-type(1) {
    transform: rotateY(40deg);
  }
  &:nth-of-type(2) {
    transform: rotateY(30deg);
  }
  &:nth-of-type(3) {
    transform: rotateY(20deg);
  }

  ${Wrapper}:hover & {
    &:nth-of-type(1) {
      transform: rotateY(-15deg);
    }
    &:nth-of-type(2) {
      transform: rotateY(-27deg);
    }
    &:nth-of-type(3) {
      transform: rotateY(-34deg);
    }
  }
`;

const Cover = styled.li`
  position: relative;
  transition: transform 0.3s;

  ${Wrapper}:hover & {
    transform: rotateY(-40deg);
  }
`;

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  background: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  transition: 0.3s ease;
  opacity: 0;

  ${Wrapper}:hover & {
    opacity: 1;
  }
`;

const Text = styled.div`
  position: absolute;
  background-color: var(--white);
  right: 0;
  bottom: 20px;
  height: 10%;
  width: 40%;
`;

const Book: React.FC = () => {
  return (
    <Wrapper>
      <Page>
        <img src="../../Untitled.jpg" alt="page" />
      </Page>
      <Page>
        <img src="../../Untitled.jpg" alt="page" />
      </Page>
      <Page>
        <img src="../../Untitled.jpg" alt="page" />
      </Page>
      <Cover>
        <img src="../../Cover.jpg" alt="cover" />
        <Overlay />
        <Text />
      </Cover>
    </Wrapper>
  );
};

export default Book;
