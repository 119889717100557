import { FooterProps } from 'components/types';
import Section from 'layout/Section';
import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';
import Button from '../Button';
import Copyright from './Copyright';
import General from './General';
import Projects from './Projects';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 100px;
  grid-template-rows: repeat(4, auto);
  column-gap: 24px;

  & > {
    :nth-child(1) {
      top: -15px;
    }
  }

  h4 {
    line-height: 16px;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0.1rem;
    margin-bottom: 16px;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(3, auto);
    & > {
      :nth-child(1) {
        grid-row: 1;
        margin-bottom: 64px;
        top: 0;
      }
    }
  }
`;

const Footer: React.FC<FooterProps> = ({ content }) => {
  return (
    <Section as="footer">
      <Grid>
        <Button pure href="/" icon="logo" />
        <Projects content={content} />
        <General />
        <Copyright />
      </Grid>
    </Section>
  );
};

export default Footer;
