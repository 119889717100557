import { useEffect, useState } from 'react';

const useLastActiveEntry = (sections: HTMLElement[], options?: IntersectionObserverInit) => {
  const [active, setActive] = useState<string>();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return;
        setActive(entry.target.id);
      });
    }, options);

    sections.forEach(e => {
      if (e.id === '') return;
      observer.observe(e);
    });
  }, [options, sections]);
  return active;
};

export default useLastActiveEntry;
