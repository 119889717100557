import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';
import { HamburgerProps } from 'components/types';

const Container = styled.div<{ active: boolean }>`
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
`;

const Menu = styled.div<{ active: boolean }>`
  width: 25px;
  height: 2px;
  background: ${props => (props.active ? 'transparent' : 'var(--white)')};
  border-radius: 1px;
  box-shadow: ${props => (props.active ? 'none' : '0 2px 5px rgba(255, 101, 47, 0.2)')};
  transition: all 0.2s ease-in-out;
  transform: ${props => (props.active ? 'translateX(-15px)' : '')};

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 2px;
    background: var(--white);
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.2s ease-in-out;
  }

  &::before {
    transform: ${props =>
      props.active ? 'rotate(-45deg) translate(10px, 10px)' : 'translateY(-8px)'};
  }

  &::after {
    transform: ${props =>
      props.active ? 'rotate(45deg) translate(10px, -10px)' : 'translateY(8px)'};
  }
`;
const Hamburger: React.FC<HamburgerProps> = ({ state }) => {
  const { click, setClick } = state;
  const handleClick = () => setClick(!click);

  return (
    <Container active={click} onClick={handleClick}>
      <Menu active={click}></Menu>
    </Container>
  );
};

export default Hamburger;
