import { createGlobalStyle } from 'styled-components';
import variables from './variables';
import device from './device';

const GlobalStyle = createGlobalStyle`
  ${variables};

  body {
    background-color: var(--black);
    text-align: left;
    color: var(--white);
    width: 100%;
    min-height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  html {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: var(--black) var(--orange);
  }

  body::-webkit-scrollbar {
    width: 8px;
  }

  body::-webkit-scrollbar-track {
    background: var(--black);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--black);
    border: 2px solid var(--orange);
    border-radius: 10px;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: var(--font-sans);
  }

  /* section and content styles */

  

  /* text globals */

  ul, nav {
    list-style-type: none;
  }

  h1 {
    font-size: 56px;
    font-weight: 900;
    line-height: 64px;

    @media ${device.tablet} {
      font-size: 36px;
      line-height: 44px;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
    
    @media ${device.tablet} {
      font-size: 22px;
      line-height: 30px;
    }
  }

  h3 {
    font-size: 32px;
    font-wight: 900;
    line-height: 36px;

    @media ${device.laptop} {
      font-size: 28px;
      line-height: 32px;
    }

    @media ${device.laptop} {
      font-size: 24px;
      font-height: 28px;
    }

    @media ${device.mobileL} {
      font-size: 20px;
      font-height: 24px;
    }
  }
  

  h4 {
    font-size: 14px;
    font-weight: 800;
    line-height: 22px;
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 2.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-sans);
    letter-spacing: initial;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.6px;
    color: var(--p-color);

    @media ${device.laptop} {
      font-size: 18px;
      line-height: 28px;
    }

    @media ${device.tablet} {
      font-size: 16px;
      line-height: 24px;
    }
  }

/* link globals */

  a:active,
  a:link,
  a:visited {
    color: inherit;
    text-decoration: none;
  }
`;

export default GlobalStyle;
