import { SectionProps } from 'components/types';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';
import sectionReveal from 'utils/sectionReveal';

const Wrapper = styled.section<{ bgcolor: string }>`
  background-color: ${({ bgcolor }) => bgcolor};

  @media (max-width: 1330px) {
    padding: 0 50px;
  }
  @media ${device.tablet} {
    padding: 0 50px;
  }
  @media ${device.mobileL} {
    padding: 0 25px;
  }
`;

const Content = styled.div<{ w: number }>`
  margin: 0 auto 50px;
  padding: 100px 0;
  max-width: ${({ w }) => `${w}px`};
  will-change: transform;
`;

const Section: React.FC<SectionProps> = ({
  children,
  bgcolor = 'var(--black)',
  w = 1280,
  id,
  refs,
  as,
}) => {
  const refTarget = useRef(null);
  useEffect(() => {
    if (refs) refs.current.forEach(ref => sectionReveal(ref));
    sectionReveal(refTarget.current);
  }, [refs]);

  return (
    <Wrapper as={as} id={id} bgcolor={bgcolor}>
      <Content w={w} ref={refTarget}>
        {children}
      </Content>
    </Wrapper>
  );
};

export default Section;
