import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { HeaderProps, HeaderStateProps } from 'components/types';
import ExternalLinksContent from 'content/externalLinks';
import useScrollUp from 'hooks/useScrollUp';
import device from 'styles/device';
import handleExternalLinks from 'utils/handleExternalLinks';

import SideBar from './SideBar';
import Navigation from './Navigation';
import Button from 'components/Button';

const Wrapper = styled.header<{ scrollUp: boolean }>`
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  height: var(--nav-height);
  background-color: var(--black);

  transition: all 0.2s ease-in-out;

  ${props =>
    props.scrollUp &&
    css`
      transform: translateY(calc(var(--nav-height) * -5));
    `};

  @media (max-width: 1080px) {
    padding: 0 50px;
  }
  @media ${device.tablet} {
    position: fixed;
    padding: 0 50px;
  }
  @media ${device.mobileL} {
    padding: 0 25px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90vw;
  margin: 0 auto;
  text-align: left;
  height: 100%;
`;

export const Header: React.FC<HeaderProps> = ({ content }) => {
  const [click, setClick] = useState(false);

  const HeaderState: HeaderStateProps = { click, setClick };
  const scrollUp = useScrollUp();
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    handleExternalLinks();
  }, []);

  return (
    <Wrapper scrollUp={!scrollUp && !click}>
      <Container>
        <Button
          pure
          icon="logo"
          aria-label="website-logo"
          href="/"
          onClick={closeMobileMenu}
        />
        <Navigation state={HeaderState} content={content} />
        <SideBar content={ExternalLinksContent} state={HeaderState} />
      </Container>
    </Wrapper>
  );
};

export default Header;
