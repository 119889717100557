const IconObserver = () => (
  <svg width="80" height="90" viewBox="0 0 80 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_422_7)">
      <path
        d="M0 59.0625C0 58.3166 0.263392 57.6012 0.732233 57.0738C1.20107 56.5463 1.83696 56.25 2.5 56.25H77.5C78.163 56.25 78.7989 56.5463 79.2678 57.0738C79.7366 57.6012 80 58.3166 80 59.0625C80 59.8084 79.7366 60.5238 79.2678 61.0512C78.7989 61.5787 78.163 61.875 77.5 61.875H2.5C1.83696 61.875 1.20107 61.5787 0.732233 61.0512C0.263392 60.5238 0 59.8084 0 59.0625ZM60 0H20C17.3478 0 14.8043 1.18526 12.9289 3.29505C11.0536 5.40483 10 8.26631 10 11.25V50.625H15V11.25C15 9.75816 15.5268 8.32742 16.4645 7.27252C17.4021 6.21763 18.6739 5.625 20 5.625H60C61.3261 5.625 62.5979 6.21763 63.5355 7.27252C64.4732 8.32742 65 9.75816 65 11.25V50.625H70V11.25C70 8.26631 68.9464 5.40483 67.0711 3.29505C65.1957 1.18526 62.6522 0 60 0V0ZM70 67.5H65V78.75C65 80.2418 64.4732 81.6726 63.5355 82.7275C62.5979 83.7824 61.3261 84.375 60 84.375H20C18.6739 84.375 17.4021 83.7824 16.4645 82.7275C15.5268 81.6726 15 80.2418 15 78.75V67.5H10V78.75C10 81.7337 11.0536 84.5952 12.9289 86.7049C14.8043 88.8147 17.3478 90 20 90H60C62.6522 90 65.1957 88.8147 67.0711 86.7049C68.9464 84.5952 70 81.7337 70 78.75V67.5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_422_7">
        <rect width="80" height="90" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconObserver;
