import React from 'react';
import styled from 'styled-components/macro';
import { DropdownProps } from 'components/types';
import { HashLink } from 'react-router-hash-link';
import device from 'styles/device';
import { Icon } from 'components/icons';

const Wrapper = styled.div`
  position: absolute;
  top: 64px;
  left: -55px;
  width: 300px;
  padding: 24px 40px 26px 40px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &:before {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: -5px;
    left: 100px;
    z-index: -1;
    border-radius: 3px;
    background-color: var(--white);
    transform: rotate(45deg);
  }

  @media ${device.tablet} {
    display: block;
    top: 0;
    left: 20px;
    padding: 0;
    position: relative;
    height: 0px;
    width: 100%;
    overflow: hidden;
    transition: 0.5s;
    text-align: left;

    :before {
      display: none;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 8px;
  background-color: var(--white);
  z-index: -1;

  @media ${device.tablet} {
    display: none;
  }
`;

const Description = styled.div`
  font-size: var(--fs-xxs);
  color: #8b96aa;
`;

const ListItem = styled.li`
  line-height: 1.5rem;
  letter-spacing: 0.6px;
  font-size: var(--fs-sm);
  color: black;
  font-weight: 500;

  &:hover,
  &:hover ${Description} {
    color: var(--orange);
  }

  a {
    padding: 3px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
`;

const List = styled.ul``;
const Link = styled(HashLink)``;
const Container = styled.div``;
const Title = styled.span``;

const Dropdown: React.FC<DropdownProps> = ({ content, state }) => {
  const { setClick } = state;
  const closeMobileMenu = () => setClick(false);
  return (
    <Wrapper>
      <Background />
      <List>
        {content.map((item, i) => (
          <ListItem key={i}>
            <Link
              to={`/${item.name.toString().toLowerCase()}#top`}
              onClick={closeMobileMenu}
            >
              <Icon name={item.icon} />
              <Container>
                <Title>{item.name}</Title>
                <Description>{item.quickDesc}</Description>
              </Container>
            </Link>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default Dropdown;
