import React from 'react';
import styled, { css } from 'styled-components/macro';
import device from 'styles/device';
import { Icon } from './icons';
import { ButtonProps } from './types';

const IconWrapper = styled.div<{ pure: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border: initial;
  font-weight: 900;

  ${props =>
    !props.pure &&
    css`
      margin-left: 5px;

      svg {
        height: 14px;
        width: 14px;
      }
    `}
`;

const Arrow = styled.span`
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateX(0);
  margin-left: 5px;
`;

const StyledButton = styled.button<{
  primary: boolean;
  pure?: boolean;
  arrow?: boolean;
  icon?: boolean;
  square: boolean;
}>`
  display: flex;
  position: relative;
  font-size: 16px;
  width: fit-content;
  border: none;
  border-radius: ${({ square }) => (square ? '10px' : '100px')};
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  z-index: 50;

  span {
    display: inline-block;
  }

  ${props =>
    !props.pure &&
    css`
      padding: 12px 20px;

      @media ${device.tablet} {
        padding: 12px 15px;
      }
    `}

  ${props =>
    props.primary &&
    css`
      background-color: var(--orange);
      color: var(--white);
      border-color: var(--orange);
      transition: background-color 0.15s;

      &:hover {
        background-color: var(--light-orange);
      }
    `};

  ${props =>
    !props.primary &&
    !props.pure &&
    css`
      background: transparent;
      color: var(--white);
      border-color: var(--white);
      box-shadow: inset 0px 0px 0px 1px #566171;
      transition: color 150ms, border-color 150ms, box-shadow 150ms,
        background-color 0.4s;

      &:hover {
        color: ${props.color};
        box-shadow: inset 0px 0px 0px 1px ${props.color};
      }
    `};

  ${props =>
    props.arrow &&
    css`
      &:hover {
        ${Arrow} {
          transform: translateX(45%);
        }
      }
    `}

  @media ${device.tablet} {
    font-size: 13px;
    line-height: 13px;
  }
`;

const Button: React.FC<ButtonProps> = ({
  children,
  primary = false,
  arrow = false,
  square = false,
  pure = false,
  icon,
  href,
  type,
  onClick,
}) => {
  return (
    <>
      {href ? (
        <StyledButton
          icon={icon !== undefined}
          primary={primary}
          arrow={arrow}
          square={square}
          as="a"
          href={href}
          pure={pure}
          onClick={onClick}
          aria-label={`${pure && 'website-logo'}`}
        >
          {children && children}
          {icon && (
            <IconWrapper pure={pure}>
              <Icon name={icon} />
            </IconWrapper>
          )}
          {arrow && <Arrow>&nbsp;&#10132;</Arrow>}
        </StyledButton>
      ) : (
        <StyledButton primary={primary} square type={type} onClick={onClick}>
          {children}
        </StyledButton>
      )}
    </>
  );
};

export default Button;
