const overview = {
  title: 'Overview',
  desc: 'A quick look at general information about the project with a brief description of the development process.',
  paragraphs: [
    {
      header: 'Project',
      text: 'It is a static single-page application as a personal portfolio introducing my humble person to the world. Made with React, styled with styled components, project configuration with create-react-app. It contains the front page, which is about who I am as a person, where I am coming from, and what are my aspirations in terms of web development, with links to my projects and with few words about them.',
    },
    {
      header: 'Project difficulties',
      text: `There was quite a lot of struggle with how the project should be organized in terms of design, implementation, and deployment. From React's perspective, the main difficulty was to make state management so the components would be highly reusable and coherent with provided content.`,
    },
    {
      header: 'Solutions',
      text: 'Major focused on learning best practices for what i have struggled with. For design I used Figma and tried wireframes->layout->mockup process. As a state management, I used useContext for passing content down the component tree, and locally useState for components functionality or style alterations, useEffect for component life cycle according to required functionality, if shared with other components, created as custom hook globally. I have used create react app boilerplate for the build process, to be straightforward. Deployed using linode hosting and their documentation.',
    },
  ],
  features: {
    title: 'Notable features',
    items: [
      'Navbar with mobile, animated menu.',
      'Responsiveness of the design.',
      'Responsive images, with srcset dependant on devices width.',
      'Custom hooks, for menu visibility, fetching content, or triggering animations.',
      'Content bar using Intersection Observer.',
    ],
  },
};

export default overview;
