import {
  IconArrow,
  IconExternal,
  IconBranch,
  IconGame,
  IconGlobe,
  IconGitHub,
  IconMail,
  IconLinkedin,
  Logo,
  LoaderImg,
  IconCss,
  IconHtml,
  IconReact,
  IconTypescript,
  IconStyledComponents,
  IconPhaser,
  IconPortfolio,
  IconHook,
  IconObserver,
  IconRespons,
  IconWebapp,
} from 'components/icons';

const Icon = ({ name }: { name: string }) => {
  switch (name) {
    case 'mail':
      return <IconMail />;
    case 'external':
      return <IconExternal />;
    case 'branch':
      return <IconBranch />;
    case 'github':
      return <IconGitHub />;
    case 'game':
      return <IconGame />;
    case 'linkedin':
      return <IconLinkedin />;
    case 'logo':
      return <Logo />;
    case 'loaderimg':
      return <LoaderImg />;
    case 'arrow':
      return <IconArrow />;
    case 'typescript':
      return <IconTypescript />;
    case 'html':
      return <IconHtml />;
    case 'css':
      return <IconCss />;
    case 'react':
      return <IconReact />;
    case 'globe':
      return <IconGlobe />;
    case 'phaser':
      return <IconPhaser />;
    case 'portfolio':
      return <IconPortfolio />;
    case 'hook':
      return <IconHook />;
    case 'styled-components':
      return <IconStyledComponents />;
    case 'observer':
      return <IconObserver />;
    case 'respons':
      return <IconRespons />;
    case 'webapp':
      return <IconWebapp />;
    default:
      return null;
  }
};

export default Icon;
