import overview from './overview';
import goals from './goals';

const getPhaserFarmContent = () => {
  return {
    name: 'PhaserFarm',
    icon: 'game',
    img: '../../phaser-farm.png',
    imgMobile: '../../phaser-farm.png',
    quickDesc: 'Arcade game',
    description:
      'An arcade look at farm life cycle simulation. Focused on object-oriented approach using Phaser game engine framework with TypeScript. Development environment build with both Parcel and Webpack',
    tech: ['TypeScript', 'Phaser', 'HTML', 'CSS'],
    ghurl: 'https://github.com/jotpeEe/Phaser-farm',
    url: 'http://mklos.website-eu-central-1.linodeobjects.com/farm/',
    sections: {
      overview,
      goals,
    },
  };
};

export default getPhaserFarmContent;
