const features = {
  title: 'Notable features',
  desc: 'Creme de la creme of the project.',
  paragraphs: [
    {
      icon: '',
      header: 'Animations and responsiveness',
      text: '',
    },
    {
      icon: '',
      header: 'Hooks',
      text: '',
    },
    {
      icon: '',
      header: 'Intersection observer',
      text: '',
    },
  ],
};

export default features;
