const goals = {
  title: 'Project goals',
  desc: 'Section which trying to answer question: why this project?',
  paragraphs: [
    {
      header: `What to improve, and what's new`,
      text: 'The improvement was focused on TypeScript, to be very explicit about used types. Configured prettier and eslint for clean and structured maintained code. New ground was React, component-based thinking, and CSS written with styled-components. ',
      img: '../../phaser-farm.png',
    },
    {
      header: 'Explore CSS',
      text: 'There was quite a lot of changes with the styling across the project. I was chasing ideas and how to implement them with CSS. It was endless process of trying things and replacing them with better ones in terms of structure, or design. It was important to use globalStyle, global variables and theme with styled-components, and configure the setup.',
      img: '../../phaser-farm.png',
    },
    {
      header: 'Time is of the essense',
      text: 'Went throught process of designing, implementing and deploying, Trying to find most efficient way of getting things done. Along the way i chose to do the design in Figma, and then when I am happy with the resault implementing it with scheduled manner, trying to learn time estimations for smallest tasks.',
      img: '../../phaser-farm.png',
    },
  ],
};

export default goals;
