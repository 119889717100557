const IconReact = () => (
  <svg width="104" height="36" viewBox="0 0 104 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0404 10.9016C24.7068 10.9016 29.0418 11.5712 32.3104 12.6964C36.2485 14.0523 38.6699 16.1073 38.6699 17.9682C38.6699 19.9073 36.1038 22.0905 31.8747 23.4917C28.6774 24.551 24.47 25.104 20.0404 25.104C15.4988 25.104 11.1982 24.585 7.96492 23.4801C3.87421 22.0819 1.41034 19.8704 1.41034 17.9682C1.41034 16.1224 3.72221 14.0832 7.60501 12.7295C10.8858 11.5857 15.3278 10.9016 20.0398 10.9016H20.0404Z"
      stroke="white"
      strokeOpacity="0.9"
      strokeWidth="1.66272"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8888 14.4553C16.22 10.4129 18.9656 6.99216 21.5733 4.72282C24.7152 1.98862 27.7051 0.917826 29.317 1.84736C30.9969 2.8161 31.6062 6.12983 30.7073 10.4932C30.0283 13.7922 28.4048 17.713 26.1919 21.5503C23.9229 25.4846 21.3249 28.9508 18.7528 31.1991C15.498 34.0443 12.3513 35.0739 10.7035 34.1236C9.10451 33.2022 8.49304 30.1802 9.26011 26.1402C9.90823 22.7268 11.5347 18.5371 13.888 14.4552L13.8888 14.4553Z"
      stroke="white"
      strokeOpacity="0.9"
      strokeWidth="1.66272"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8946 21.5606C11.5567 17.5229 9.96504 13.435 9.30064 10.0425C8.50087 5.95496 9.06634 2.82974 10.6768 1.89751C12.3551 0.925929 15.5302 2.05298 18.8616 5.01096C21.3803 7.24731 23.9667 10.6113 26.186 14.445C28.4615 18.3755 30.1669 22.3575 30.8303 25.7087C31.6698 29.9495 30.9903 33.1898 29.344 34.1429C27.7467 35.0676 24.8235 34.0885 21.7066 31.4063C19.0731 29.1401 16.2555 25.6385 13.8946 21.5606V21.5606Z"
      stroke="white"
      strokeOpacity="0.9"
      strokeWidth="1.66272"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.62 26.848L54.36 20.412C56.513 20.222 58.453 18.662 58.453 15.812C58.453 12.912 56.395 11.056 53.508 11.056H47.168V26.836H49.132V20.566H52.279L56.301 26.836L58.62 26.848ZM53.273 18.851H49.133V12.818H53.273C55.143 12.818 56.42 14.048 56.42 15.823C56.42 17.598 55.142 18.853 53.273 18.853V18.851ZM65.931 27.131C67.801 27.131 69.338 26.516 70.474 25.381L69.622 24.221C68.722 25.144 67.398 25.664 66.097 25.664C63.637 25.664 62.122 23.866 61.98 21.714H71.23V21.264C71.23 17.834 69.195 15.136 65.74 15.136C62.475 15.136 60.11 17.81 60.11 21.122C60.11 24.695 62.547 27.122 65.93 27.122L65.931 27.131ZM69.481 20.411H61.981C62.076 18.661 63.281 16.601 65.719 16.601C68.322 16.601 69.457 18.707 69.481 20.411ZM83.015 26.847V18.992C83.015 16.224 81.005 15.135 78.591 15.135C76.721 15.135 75.255 15.75 74.025 17.028L74.853 18.258C75.87 17.17 76.983 16.673 78.355 16.673C80.011 16.673 81.242 17.548 81.242 19.086V21.144C80.319 20.079 79.018 19.582 77.456 19.582C75.516 19.582 73.456 20.789 73.456 23.344C73.456 25.828 75.514 27.13 77.456 27.13C78.994 27.13 80.296 26.586 81.242 25.545V26.845L83.015 26.847ZM78.095 25.853C76.439 25.853 75.279 24.813 75.279 23.369C75.279 21.902 76.439 20.861 78.095 20.861C79.349 20.861 80.555 21.334 81.242 22.281V24.434C80.556 25.38 79.349 25.853 78.095 25.853ZM91.595 27.131C93.677 27.131 94.907 26.279 95.783 25.144L94.6 24.056C93.843 25.073 92.873 25.546 91.7 25.546C89.263 25.546 87.75 23.653 87.75 21.122C87.75 18.591 89.264 16.722 91.7 16.722C92.883 16.722 93.853 17.172 94.6 18.212L95.783 17.124C94.908 15.988 93.677 15.137 91.595 15.137C88.188 15.137 85.893 17.74 85.893 21.123C85.893 24.53 88.188 27.123 91.595 27.123V27.131ZM101.155 27.131C102.195 27.131 102.835 26.823 103.285 26.398L102.765 25.073C102.528 25.333 102.065 25.546 101.558 25.546C100.778 25.546 100.398 24.931 100.398 24.079V16.981H102.718V15.42H100.398V12.297H98.623V15.42H96.73V16.982H98.623V24.459C98.623 26.163 99.475 27.133 101.155 27.133V27.131Z"
      fill="white"
      fillOpacity="0.9"
    />
  </svg>
);

export default IconReact;
