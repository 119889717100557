import { css } from 'styled-components';

const variables = css`
  :root {
    --white: #fff;
    --light-gray: hsl(0, 0%, 100%, 0.75);
    --gray: hsl(0, 0%, 100%, 0.55);
    --black: hsl(210, 13%, 6%);
    --blue: hsl(211, 100%, 49%);
    --text-gray: rgb(255, 255, 255, 0.75);
    --orange: hsl(39, 100%, 40%);
    --light-orange: hsl(39, 100%, 50%);
    --dark-orange: hsl(39, 85%, 5%);
    --bg-gray: hsl(206, 14%, 10%);
    --p-color: hsl(217, 16%, 68%);
    --nav-padding: 20%;
    --section-padding: 0px 150px;
    --nav-logo-icons-padding: 10px;
    --font-sans: 'Lato', system-ui, sans-serif;
    --project-small-font-size: 13px;
    --project-big-font-size: 28px;
    --nav-height: 60px;

    --fs-xxs: 12px;
    --fs-xs: 13px;
    --fs-sm: 14px;
    --fs-md: 16px;
    --fs-lg: 18px;
    --fs-xl: 18px;
    --fs-xxl: 24px;
    --fs-heading: 32px;
  }
`;

export default variables;
