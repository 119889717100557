import React from 'react';
import styled from 'styled-components/macro';
import { NextProps } from 'components/types';
import { HashLink } from 'react-router-hash-link';
import device from 'styles/device';
import SectionLayout from 'layout/Section';

const Link = styled(HashLink)`
  color: var(--black) !important;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
    opacity: 0.6;
    color: var(--black);

    @media ${device.tablet} {
      font-size: 14px;
      line-height: 22px;
    }
  }

  h3 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 900;

    @media ${device.tablet} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Next: React.FC<NextProps> = ({ content }) => {
  const { name, desc } = content;
  return (
    <SectionLayout bgcolor="var(--white)">
      <Link to={`/${name}#top`}>
        <p>Next Project</p>
        <h3>{`${name}: ${desc?.toLocaleLowerCase()} →`}</h3>
      </Link>
    </SectionLayout>
  );
};

export default Next;
