import React from 'react';
import styled from 'styled-components';
import SectionLayout from 'layout/Section';
import Book from 'components/sections/Home/OtherProjects/Book';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 35px;
  padding: 50px 0;
`;

const OtherProjects: React.FC = () => {
  return (
    <SectionLayout>
      <h2>Other projects</h2>
      <Container>
        <Book />
        <Book />
        <Book />
        <Book />
        <Book />
      </Container>
    </SectionLayout>
  );
};

export default OtherProjects;
