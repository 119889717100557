import React from 'react';
import styled from 'styled-components/macro';
import SectionTitle from 'components/SectionTitle';
import device from 'styles/device';
import Paragraph from 'components/Paragraph';
import { FeaturesSectionProps } from 'components/types';
import SectionLayout from 'layout/Section';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
  }
`;

const Features: React.FC<FeaturesSectionProps> = ({ content }) => {
  const { title, desc, paragraphs } = content;

  return (
    <SectionLayout id="Features">
      <SectionTitle title={title} desc={desc} />
      <Grid>
        {paragraphs.map((paragraph, index) => (
          <Paragraph
            key={index}
            header={paragraph.header}
            text={paragraph.text}
            icon={paragraph.icon}
          />
        ))}
      </Grid>
    </SectionLayout>
  );
};

export default Features;
