import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/globalstyle';
import theme from 'styles/theme';

import Project from 'components/pages/Project';
import Home from 'components/pages/Home';
import { getProjects } from 'content/projects';

const App = () => {
  const projects = getProjects();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home content={{ projects: projects }} />} />
          {projects.map((project, index) => (
            <Route
              key={index}
              path={project.name.toLowerCase()}
              element={
                <Project
                  content={{
                    projects: projects,
                    project: project,
                    index: index,
                  }}
                />
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
