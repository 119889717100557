import axios from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import Button from 'components/Button';
import device from 'styles/device';

const StyledForm = styled.form<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${({ active }) => (active ? '20px 50px' : '0px')};
  opacity: ${({ active }) => (active ? '1' : '0')};

  & > * {
    display: none;
  }

  ${props =>
    props.active &&
    css`
      & > * {
        display: initial;
      }

      transition: opacity 0.2s ease-in-out 0.3s;
    `}

  textarea {
    min-height: 200px;
  }

  @media ${device.tablet} {
    padding: 0px;
  }
`;

const Input = styled.input`
  border-radius: 10px;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;

  :hover {
    border: 2px solid var(--white);
  }
`;

const ErrorMessage = styled.p``;

export type FormProps = {
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
};

const GETFORM_FORM_ENDPOINT =
  'https://getform.io/f/137eee7b-8436-44be-97ce-b4e861ddd41f';

const Form: React.FC<FormProps> = ({ active, setActive }) => {
  const [query, setQuery] = useState({
    email: '',
    name: '',
    message: '',
  });

  const handleChange = () => (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post(GETFORM_FORM_ENDPOINT, formData, {
        headers: { Accept: 'application/json' },
      })
      .then(function (response) {
        setActive(!active);
        setQuery({
          name: '',
          email: '',
          message: '',
        });
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {
        <StyledForm
          active={active}
          acceptCharset="UTF-8"
          method="POST"
          encType="multipart/form-data"
          id="ajaxForm"
          onSubmit={handleSubmit}
        >
          <Input
            type="email"
            name="email"
            aria-describedby="emailHelp"
            placeholder="Your email..."
            required
            value={query.email}
            onChange={handleChange()}
            autoComplete="off"
          />
          <Input
            type="text"
            name="name"
            aria-describedby="nameHelp"
            placeholder="Your name..."
            required
            value={query.name}
            onChange={handleChange()}
            autoComplete="off"
          />
          <Input
            as="textarea"
            name="message"
            aria-describedby="messageHelp"
            placeholder="Your message..."
            required
            value={query.message}
            onChange={handleChange()}
            autoComplete="off"
          />
          <ErrorMessage>Before you provide </ErrorMessage>
          <Button square primary type="submit">
            Submit
          </Button>
        </StyledForm>
      }
    </>
  );
};

export default Form;
