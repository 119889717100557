import Button from 'components/Button';
import { Icon } from 'components/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';

const StyledHome = styled.section`
  height: 100vh;
  position: relative;
  margin: auto;

  @media (max-width: 1330px) {
    padding: 0 50px;
  }
  @media ${device.tablet} {
    padding: 0 50px;
  }
  @media ${device.mobileL} {
    padding: 0 25px;
  }
`;

const Container = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 1280px;
  border-radius: 16px;
`;

const Content = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: all 0.7s ease-in-out 0.5s;

  p {
    margin: 16px 0px;
    max-width: 540px;
  }

  h4 {
    margin-bottom: 16px;
    color: var(--orange);
    font-size: 24px;

    @media (max-width: 1128px) {
      font-size: 14px;
    }
  }
`;

const GlobeWrapper = styled.div<{ active: boolean }>`
  transition: all 0.7s ease-in-out 0.5s;
  opacity: ${({ active }) => (active ? '1' : '0')};

  svg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media ${device.laptop} {
    display: none;
  }

  .home-globe.animate {
    opacity: 1;
  }
`;

const BackgroundImage = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-width: 100vw;
  background-image: url(../../bg-big.png);
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-clip: border-box;
  transition: all 0.5s ease-in-out;
  opacity: ${({ active }) => (active ? '0.15' : '0')};
`;

const Hero: React.FC = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <StyledHome>
      <Container>
        <Content active={animate}>
          <h4>Hi, my name is Maciej and I&apos;m a</h4>
          <h1>Software engineer.</h1>
          <p>
            I’m specializing in building (and occasionally designing)
            exceptional digital experiences. Currently, I’m focused on building
            accessible, human-centered products.
          </p>
          <Button primary square arrow>
            Resume
          </Button>
        </Content>
        <GlobeWrapper active={animate}>
          <Icon name="globe" />
        </GlobeWrapper>
      </Container>
      <BackgroundImage active={animate} />
    </StyledHome>
  );
};

export default Hero;
