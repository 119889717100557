const IconGame = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    stroke="currentColor"
    strokeWidth="2"
    fill="currentColor"
  >
    <g>
      <g>
        <g>
          <path
            d="M173.871,296.61h-20.609v-20.607c0-10.382-8.447-18.83-18.83-18.83H111.82c-10.382,0-18.83,8.447-18.83,18.83v20.607
				H72.384c-10.382,0-18.83,8.448-18.83,18.83v22.613c0,10.382,8.447,18.83,18.83,18.83H92.99v20.607
				c0,10.382,8.447,18.83,18.83,18.83h22.613c10.382,0,18.83-8.448,18.83-18.83v-20.607h20.608c10.382,0,18.83-8.448,18.83-18.83
				V315.44C192.7,305.057,184.253,296.61,173.871,296.61z M173.871,340.742h-28.679c-4.458,0-8.07,3.612-8.07,8.07v28.677
				c0,1.458-1.232,2.69-2.69,2.69H111.82c-1.458,0-2.69-1.232-2.69-2.69v-28.677c0-4.458-3.612-8.07-8.07-8.07H72.384
				c-1.458,0-2.69-1.232-2.69-2.69V315.44c0-1.458,1.232-2.69,2.69-2.69h28.676c4.458,0,8.07-3.612,8.07-8.07v-28.677
				c0-1.458,1.232-2.69,2.69-2.69h22.613c1.458,0,2.69,1.232,2.69,2.69v28.677c0,4.458,3.612,8.07,8.07,8.07h28.678
				c1.458,0,2.69,1.232,2.69,2.69v22.613h0.001C176.561,339.51,175.329,340.742,173.871,340.742z"
          />
          <path
            d="M226.949,257.173h-25.823c-4.458,0-8.07,3.612-8.07,8.07s3.612,8.07,8.07,8.07h25.823c4.458,0,8.07-3.612,8.07-8.07
				S231.405,257.173,226.949,257.173z"
          />
          <path
            d="M318.945,265.243c0-4.458-3.612-8.07-8.07-8.07h-25.823c-4.458,0-8.07,3.612-8.07,8.07s3.612,8.07,8.07,8.07h25.823
				C315.331,273.313,318.945,269.7,318.945,265.243z"
          />
          <path
            d="M388.88,310.616c16.617,0,30.137-13.518,30.137-30.137c0-16.618-13.518-30.137-30.137-30.137
				c-16.617,0-30.137,13.52-30.137,30.137C358.743,297.096,372.262,310.616,388.88,310.616z M388.88,266.482
				c7.718,0,13.997,6.279,13.997,13.997s-6.279,13.997-13.997,13.997c-7.718,0-13.997-6.279-13.997-13.997
				S381.162,266.482,388.88,266.482z"
          />
          <path
            d="M435.148,296.609c-16.617,0-30.137,13.519-30.137,30.137s13.518,30.137,30.137,30.137
				c16.616,0,30.136-13.519,30.136-30.137S451.764,296.609,435.148,296.609z M435.148,340.743c-7.718,0-13.997-6.279-13.997-13.997
				s6.279-13.997,13.997-13.997s13.996,6.279,13.996,13.997S442.865,340.743,435.148,340.743z"
          />
          <path
            d="M393.658,208.405h-67.767v-8.07c0-13.349-10.861-24.209-24.209-24.209h-37.613v-6.814
				c0-21.556,17.538-39.094,39.094-39.094h54.022c30.457,0,55.234-24.778,55.234-55.234c0-4.458-3.612-8.07-8.07-8.07
				s-8.07,3.612-8.07,8.07c0,21.556-17.538,39.094-39.094,39.094h-54.022c-30.457,0-55.234,24.778-55.234,55.234v6.814h-37.613
				c-13.349,0-24.209,10.861-24.209,24.209v8.07h-67.761c-0.002-0.001-0.004-0.001-0.006,0C53.087,208.405,0,261.492,0,326.746
				C0,392,53.087,445.087,118.341,445.087c19.396,0,38.637-4.798,55.644-13.875c0.183-0.098,0.362-0.202,0.535-0.312
				c20.841-12.292,45.153-23.63,81.48-23.63c36.276,0,60.568,11.302,81.385,23.57c0.203,0.132,0.413,0.256,0.629,0.373
				c17.008,9.076,36.248,13.874,55.645,13.874c65.254,0,118.341-53.087,118.341-118.341S458.913,208.405,393.658,208.405z
				 M202.248,200.335c0-4.45,3.62-8.07,8.07-8.07h91.365c4.45,0,8.07,3.62,8.07,8.07v8.07H202.248V200.335z M393.658,428.947
				c-16.632,0-33.126-4.08-47.724-11.802c-0.004-0.002-0.009-0.005-0.013-0.008C323.059,403.63,296.343,391.13,256,391.13
				c-40.298,0-67.003,12.477-89.854,25.972c-14.617,7.75-31.141,11.845-47.805,11.845c-56.354,0-102.201-45.847-102.201-102.201
				c0-56.201,45.602-101.955,101.748-102.199c0.3,0.017,0.607,0.018,0.917-0.001h274.853c56.354,0,102.201,45.846,102.201,102.2
				S450.012,428.947,393.658,428.947z"
          />
          <path
            d="M388.881,342.877c-16.617,0-30.137,13.518-30.137,30.137c0,16.617,13.519,30.137,30.137,30.137
				c16.616,0,30.136-13.519,30.136-30.137S405.497,342.877,388.881,342.877z M388.881,387.011c-7.718,0-13.997-6.279-13.997-13.997
				s6.279-13.997,13.997-13.997s13.996,6.279,13.996,13.997S396.599,387.011,388.881,387.011z"
          />
          <path
            d="M372.75,326.747c0-16.617-13.519-30.137-30.137-30.137c-16.617,0-30.137,13.519-30.137,30.137
				c0,16.617,13.519,30.137,30.137,30.137C359.23,356.884,372.75,343.364,372.75,326.747z M342.613,340.744
				c-7.718,0-13.997-6.279-13.997-13.997s6.279-13.997,13.997-13.997s13.997,6.279,13.997,13.997S350.331,340.744,342.613,340.744z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default IconGame;
