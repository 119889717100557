import getPhaserFarmContent from './PhaserFarm';
import getWebsiteContent from './Website';
import getRecipesContent from './Recipes';
import ContentProject from 'content/types';

const projects: ContentProject[] = [
  getRecipesContent(),
  getWebsiteContent(),
  getPhaserFarmContent(),
];

export const getProjects = () => {
  return projects;
};
