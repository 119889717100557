const IconCss = () => (
  <svg width="102" height="44" viewBox="0 0 102 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.4417 14.3461H67.6429V19.2746H59.3804V24.2032H67.6429V29.1318H53.4417V14.3461Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M70.4832 14.3461H84.6844V18.6318H76.4219V19.4889H84.6844V29.3461H70.4832V24.8461H78.7457V23.9889H70.4832V14.3461Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M87.5246 14.3461H101.726V18.6318H93.4633V19.4889H101.726V29.3461H87.5246V24.8461H95.7871V23.9889H87.5246V14.3461Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      d="M35.004 39.3051L19.2327 43.6774L3.50487 39.3113L0 0H38.5125L35.004 39.3051Z"
      fill="white"
      fillOpacity="0.65"
    />
    <path
      d="M19.2563 40.3354L32.0002 36.8023L34.9985 3.21429H19.2563V40.3354Z"
      fill="white"
      fillOpacity="0.4"
    />
    <path d="M19.2563 17.7943H8.03485L8.46698 22.6156H19.2563V17.7943Z" fill="#EBEBEB" />
    <path d="M19.2396 8.03571H7.16658L7.60486 12.8571H19.2563V8.03571H19.2396Z" fill="#EBEBEB" />
    <path
      d="M19.2351 30.3215L13.8656 28.8716L13.5223 25.0264H10.9131H8.6824L9.35788 32.5966L19.2341 35.3383L19.2563 35.3321V30.3159L19.2351 30.3215Z"
      fill="#EBEBEB"
    />
    <path
      d="M26.0334 12.8571L25.5947 17.7943H19.2396V22.6156H25.1766L24.6169 28.8685L19.2396 30.3199V35.336L29.1237 32.5966L29.1962 31.782L30.3292 19.089L30.4468 17.7943L31.3172 8.03571H19.2396V12.8571H26.0334Z"
      fill="white"
      fillOpacity="0.9"
    />
  </svg>
);

export default IconCss;
