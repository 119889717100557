import React, { useRef } from 'react';
import { SectionProjectsProps } from 'components/types';
import SectionLayout from 'layout/Section';
import Project from 'components/Project';

const Projects: React.FC<SectionProjectsProps> = ({ content }) => {
  const revealProjects = useRef([]);
  return (
    <SectionLayout id="projects" refs={revealProjects}>
      <h2>Featured Projects</h2>
      <ul>
        {content.map((item, i) => (
          <Project key={i} index={i} refs={revealProjects} project={item} odd={i % 2 === 1} />
        ))}
      </ul>
    </SectionLayout>
  );
};

export default Projects;
