import features from './features';
import goals from './goals';
import overview from './overview';

const getRecipesContent = () => {
  return {
    name: 'Recipes',
    icon: 'webapp',
    quickDesc: 'Web application',
    description:
      'An arcade look at farm life cycle simulation. Focused on object-oriented approach using Phaser game engine framework with TypeScript. Development environment build with both Parcel and Webpack',
    tech: ['TypeScript', 'Phaser', 'HTML', 'CSS'],
    ghurl: 'https://github.com/jotpeEe/Phaser-farm',
    url: 'http://mklos.website-eu-central-1.linodeobjects.com/farm/',
    sections: {
      overview,
      goals,
      features,
    },
  };
};

export default getRecipesContent;
