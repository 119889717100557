import ContentProject from 'content/types';
import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';
import Button from './Button';
import { Icon } from './icons';
import List from './List';
import Paragraph from './Paragraph';

const Wrapper = styled.li`
  padding: 50px 0px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 75px;

  @media (max-width: 1000px) {
    column-gap: 30px;
    background: var(--bg-gray);
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    text-align: left;
  }

  & > img,
  & > svg {
    background-size: cover;
    border-radius: 5px;
    width: 100%;
    height: auto;
    object-fit: cover;
    grid-row: 1;

    @media ${device.laptop} {
      grid-column: 1 / -1;
      opacity: 0.25;
      height: 100%;
    }
  }

  & > svg {
    filter: grayscale(100%);
    padding: 100px;
    border: 1px solid var(--gray);
  }
`;

const Content = styled.div`
  min-width: 450px;
  grid-row: 1;

  @media ${device.laptop} {
    min-width: auto;
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    grid-column: 1 / -1;
    z-index: 5;
    padding: 20px;
  }
`;

const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  & > a {
    margin-right: 10px;
  }
`;

const Overline = styled.p`
  margin: 10px 0;
  font-size: 16px;
  color: #a0aaba;
`;

export type ProjectProps = {
  project: ContentProject;
  odd?: boolean;
  index: number;
  refs: React.MutableRefObject<never[]>;
};

const Project: React.FC<ProjectProps> = ({ project, odd, index, refs }) => {
  return (
    <Wrapper key={index} ref={(el: never) => (refs.current[index] = el)}>
      <Container>
        {odd && !project.img && <Icon name="logo" />}
        {odd && project.img && <img src={project.img} alt="" loading="lazy" />}
        <Content>
          <Overline>{project.quickDesc}</Overline>
          <Paragraph header={project.name} text={project.description} />
          <List widgets list={project.tech} />
          <Buttons>
            {project.url && (
              <Button square primary icon="external" href={project.url}>
                Play it
              </Button>
            )}
            {project.ghurl && (
              <Button square icon="github" href={project.ghurl}>
                Open in github
              </Button>
            )}
          </Buttons>
        </Content>
        {!odd && !project.img && <Icon name="logo" />}
        {!odd && project.img && <img src={project.img} alt="" loading="lazy" />}
      </Container>
    </Wrapper>
  );
};

export default Project;
