import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';
import useLastActiveEntry from 'hooks/useLastActiveEntry';
import { HashLink } from 'react-router-hash-link';
import { SectionsNavProps } from 'components/types';

const Wrapper = styled.div`
  position: sticky;
  top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  @media ${device.tablet} {
    padding: 0px 15px;
  }
`;

const Nav = styled.nav`
  display: inline-block;
  background-color: var(--white);
  border-radius: 50px;
  padding: 4px;
  box-shadow: 0px 6px 20px hsl(0, 0%, 5%);

  @media ${device.tablet} {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Container = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #adadad;

  @media ${device.tablet} {
    overflow: auto;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Link = styled(HashLink)`
  font-size: var(--fs-xs);
  line-height: 22px;
  font-weight: 500;
  padding: 12px 25px;

  &.active {
    color: var(--black);
  }

  @media ${device.tablet} {
    white-space: nowrap;
  }
`;

const options = { rootMargin: '-30%' };

const SectionsNav: React.FC<SectionsNavProps> = ({ content }) => {
  const [sections, name] = content;
  const active = useLastActiveEntry(sections, options);

  return (
    <Wrapper>
      <Nav>
        <Container>
          {sections.map((item, index) => (
            <Link
              key={index}
              to={`#${item.id}`}
              className={`${active === item.id ? 'active' : ''}`}
            >
              {item.id}
            </Link>
          ))}
          <Link to={`/${name}#top`}>Next project</Link>
        </Container>
      </Nav>
    </Wrapper>
  );
};

export default SectionsNav;
