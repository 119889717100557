const goals = {
  title: 'Project goals',
  desc: 'Section which trying to answer question: why this project?',
  paragraphs: [
    {
      header: 'Object Oriented Paradigm',
      text: 'Coming from a university where I mostly used Java, which is a heavily object-oriented language, I knew JavaScript uses both, an object-oriented approach and functional programming. So I thought it would be natural to start from the first one.',
      img: '',
    },
    {
      header: 'Difficulties',
      text: 'An arcade look at farm life cycle simulation. Focused on object-oriented. An arcade look at farm life cycle simulation. Focused on object-oriented.An arcade look at farm life cycle simulation. Focused on object-oriented.',
      img: '',
    },
    {
      header: 'Trying to find my own way',
      text: 'The main goal was to find the objectively best platform for the transition from Java to web development. I always enjoyed playing games and loved the arcane vibe of old-time classics. So I started with something, which gave me a lot of fun while learning a bunch of new things.',
      img: '',
    },
  ],
};

export default goals;
