import { MainProps } from 'components/types';
import useCheckActiveSections from 'hooks/useCheckActiveSections';
import React from 'react';
import Features from './Features';
import Goals from './Goals';
import Overview from './Overview';
import SectionsNav from './SectionNav';
import Techstack from './TechStack';

const Main: React.FC<MainProps> = ({ content }) => {
  const { project, next } = content;
  const sections = useCheckActiveSections(project);

  return (
    <main>
      <SectionsNav content={[sections, next.name]} />
      <Overview content={project} />
      {project.sections.goals && <Goals content={project.sections.goals} />}
      {project.sections.tech && <Techstack content={project.sections.tech} />}
      {project.sections.features && (
        <Features content={project.sections.features} />
      )}
    </main>
  );
};

export default Main;
