import React from 'react';
import SectionLayout from 'layout/Section';
import Content from './Content';

type ContactProps = {
  fixed?: boolean;
};

const Contact: React.FC<ContactProps> = ({ fixed = false }) => {
  return (
    <>
      {!fixed && (
        <SectionLayout id="contact" w={900}>
          <Content fixed={fixed} />
        </SectionLayout>
      )}
      {fixed && <Content fixed={fixed} />}
    </>
  );
};

export default Contact;
