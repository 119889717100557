const AboutContent = {
  en: {
    title: 'About me',
    background: (
      <p>
        Hello! I started my journey with coding at{' '}
        <a href="https://pja.edu.pl/en/">Polish-Japanese Academy of Information Technology </a> in
        Warsaw where we used Java and some C++. As a student i enjoyed competetive gaming, so my
        first few projects involved games, or tools built around them.
      </p>
    ),
    skills: [
      'HTML',
      'CSS',
      'JavaScript(ES6+)',
      'TypeScript',
      'React',
      'Material-UI',
      'Styled Components',
    ],
    now: 'It felt as a natural and intuitive framework for me to learn programming conecepts and realised that visual side of creating things is what naturally resonates with me.  ',
    devskills: ['Node.js', 'Express', 'GraphQL', 'PostgreSQL'],
    next: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam rerum, cupiditate atque alias placeat neque cumque optio iste obcaecati incidunt totam. Quidem quisquam, magni laborum facilis similique quas animi nulla?',
  },
};

export default AboutContent;
