import features from './features';
import goals from './goals';
import overview from './overview';
import tech from './tech';

const getWebsiteContent = () => {
  return {
    name: 'Website',
    icon: 'portfolio',
    img: '../../Front.png',
    imgMobile: '../../phaser-farm.png',
    quickDesc: 'Personal portfolio',
    description:
      'A react website which serves to showcase myskills, past projects, and experience with front-end development.',
    tech: ['HTML', 'CSS', 'TypeScript', 'React', 'Styled-components'],
    ghurl: 'https://github.com/jotpeEe/website',
    url: 'https://mklos.co',
    sections: {
      overview,
      goals,
      tech,
      features,
    },
  };
};

export default getWebsiteContent;
