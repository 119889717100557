const IconPhaser = () => (
  <svg
    width="104"
    height="22"
    viewBox="0 0 104 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="104" height="22" fill="url(#pattern0)" fillOpacity="0.8" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_405_258" transform="scale(0.00961538 0.0454545)" />
      </pattern>
      <image
        id="image0_405_258"
        width="104"
        height="22"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAAAWCAYAAAAsNNkQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAaxJREFUeNrsmgHOgyAMhcE7eSwvpQflH1m2MEbb9xAU99uEbCFUaT8fhTnvnAuuo4UQfPz03gdkXG6SHzv+ihZjnEaeoJbsXwKh2ZTQqmqSv/JUQONSW9f1PT5+B5++pvEc5V/KSXher84k/6T/dVNoXD5+nucvn9hn+bWO5yj/PCfT6BJflgXq+2UbTkGaeiwV/RsFPYpwsdUU+tqCrinFUtG2bWK/FJsWn+az179KQa/+vLEKIvxh9WgqSu/12FAU/WK/ND82nhb+Wk4cm/iOS8JHgqXkSokuLXMMpN5LJHNdCpCVqJaAGPWgtYiF1BsQ8uBRgKxk9QBUCiLOozQXSUV7l7tegJCHjwJkUW8VkBZAmkQJXmtIvQCRqxK/SdhbFK1xFgALoNWI5a5bPvIYFBWPBQhNvgWxBaTe+cjnUJo/uouzfjdqclBlEo+AHO2gasWg7EjHAMQuXUAtGhKQthkSznVjAEKLPwr0CoAQFQ0DiD1ISlCvAIhRkQOKHQTI8meLrDaHPdc6u1krQa6iG9BJgFAVefSt5m3HvtL/euV92zl/CrHG/AkwAI3wH+rAIdEjAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);

export default IconPhaser;
