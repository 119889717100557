import ContentProject from 'content/types';
import React from 'react';
import styled from 'styled-components/macro';
import ListItem from './ListItem';

const Wrapper = styled.div``;

const Header = styled.h5`
  font-size: 10px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.1rem;
  margin-bottom: 8px;
`;

const Container = styled.ul``;

export type ProjectProps = {
  project: ContentProject;
};

const Project: React.FC<ProjectProps> = ({ project }) => {
  return (
    <Wrapper>
      <Header>{project.name.toUpperCase()}</Header>
      <Container>
        {Object.entries(project.sections).map(([key], index) => (
          <ListItem key={index} name={project.name} section={key} />
        ))}
      </Container>
    </Wrapper>
  );
};

export default Project;
