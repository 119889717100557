import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';

import SectionLayout from 'layout/Section';
import List from 'components/List';
import SectionTitle from 'components/SectionTitle';
import Paragraph from 'components/Paragraph';

import { ProjectSectionProps } from 'components/types';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 0.5fr);
  colum-gap: auto;
  grid-auto-flow: row;

  & > {
    :nth-child(1) {
      grid-column-start: 2;
      grid-row: 1 / span 2;
    }

    :nth-child(2) {
      grid-column-start: 2;
      grid-row: 3 / span 2;
    }

    :nth-child(3) {
      grid-column-start: 2;
      grid-row: 5 / span 2;
    }

    :nth-child(4) {
      grid-column-start: 1;
      grid-row: 1 / span 3;
    }

    :nth-child(5) {
      grid-column-start: 1;
      grid-row: 4 / span 3;
    }
  }

  & > div {
    @media ${device.tablet} {
      grid-column-start: unset !important;
      grid-row: unset !important;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const Overview: React.FC<ProjectSectionProps> = ({ content }) => {
  const { tech } = content;
  const { title, desc, paragraphs, features } = content.sections.overview;

  return (
    <SectionLayout id="Overview">
      <SectionTitle title={title} desc={desc} />
      <Grid>
        {paragraphs &&
          paragraphs.map((item, index) => (
            <Paragraph key={index} header={item.header} text={item.text} />
          ))}
        <List large single title={features.title} list={features.items} />
        <List large single widgets title="Tech stack" list={tech} />
      </Grid>
    </SectionLayout>
  );
};

export default Overview;
