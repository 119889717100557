import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components/macro';
import device from 'styles/device';
import { Icon } from 'components/icons';
import { NavItemProps } from 'components/types';
import Dropdown from './Dropdown';

const Wrapper = styled.li<{ active: boolean; withContent: boolean }>`
  position: relative;

  &:hover {
    & > div:last-of-type {
      visibility: visible;
      opacity: 1;

      @media ${device.tablet} {
        height: 210px;
        padding: 10px 0px;
      }
    }
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:hover {
      background-color: var(--white);
      border-radius: 0;
    }
  }
`;

const Link = styled(HashLink)`
  position: relative;
  display: inline-block;
  padding: 15px 20px 15px 20px;

  @media ${device.tablet} {
    text-align: center;
    width: 100%;

    &:hover {
      background-color: var(--white);
      border-radius: 0;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 5px;
    left: 0;
    background-color: var(--orange);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  ${Wrapper}:hover &, ${Wrapper}:hover &::before {
    visibility: visible;
    transform: scaleX(1);
  }

  svg {
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
  }

  ${Wrapper}:hover & > svg {
    transform: rotate(3.142rad);
  }

  @media ${device.tablet} {
    ${Wrapper}:hover &, ${Wrapper}:hover &::before {
      color: #111;
      visibility: visible;
      transform: scaleX(1);
    }
  }
`;

const Text = styled.h5`
  position: relative;
  text-decoration: none;
  font-size: var(--fs-sm);
  font-weight: 800;
  opacity: 0.8;

  ${Wrapper}:hover & {
    opacity: 1;
  }
`;

const NavItem: React.FC<NavItemProps> = ({ content, href, state, text }) => {
  const { setClick, click } = state;
  const closeMobileMenu = () => setClick(false);

  return (
    <Wrapper active={click} withContent={content !== undefined}>
      <Link to={href} onClick={closeMobileMenu}>
        <Container>
          <Text>{text}</Text>
          {content ? <Icon name="arrow" /> : ''}
        </Container>
      </Link>
      {content ? <Dropdown content={content} state={state} /> : ''}
    </Wrapper>
  );
};

export default NavItem;
