import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: -1;
  display: flex;

  p {
    font-size: 14px;
    line-height: 24px;
    transition: color 0.15s;
  }
`;

export type CopyrightProps = {};

const Copyright: React.FC<CopyrightProps> = () => {
  return (
    <Wrapper>
      <p>All rights reserved © Maciej Klos 2022</p>
    </Wrapper>
  );
};

export default Copyright;
