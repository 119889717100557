const ExternalLinksContent = [
  {
    name: 'github',
    url: 'https://github.com/jotpeEe',
  },
  {
    name: 'mail',
    url: 'mailto: maciekklospl@gmail.com',
  },
];

export default ExternalLinksContent;
