import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';
import { Icon } from './icons';
import { WidgetsProps } from './types';

const Wrapper = styled.ul<{ listGrid: number }>`
  display: grid;
  grid-template-columns: repeat(${({ listGrid }) => listGrid}, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 60px;
  margin: 30px 0px;

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
`;

const Item = styled.li`
  display: flex;
  justify-content: center;

  svg {
    height: 80px;
  }
`;

const Widgets: React.FC<WidgetsProps> = ({ list }) => {
  return (
    <Wrapper listGrid={list.length}>
      {list.map((item, index) => (
        <Item key={index}>
          <Icon name={`${item.toString().toLowerCase()}`} />
        </Item>
      ))}
    </Wrapper>
  );
};

export default Widgets;
