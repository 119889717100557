import Button from 'components/Button';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import device from 'styles/device';
import Form from './Form';

const StyledContent = styled.div<{ fixed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--bg-gray);
  border-radius: 16px;
  padding: 60px 150px;
  z-index: 50;
  min-width: 250px;

  ${props =>
    props.fixed &&
    css`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 900px;

      @media ${device.tablet} {
        width: 100%;
        margin-top: var(--nav-height);
      }
    `};

  & > * {
    margin-bottom: 16px;
  }

  p {
    text-align: center;
  }

  @media ${device.laptopL} {
    margin: 0px;
    padding: 40px 40px 30px;

    p {
      font-size: 14px;
    }
  }

  @media ${device.tablet} {
    padding: 10px 20px;
  }
`;

const Buttons = styled.div`
  display: flex;

  & > button:nth-child(1) {
    margin-right: 10px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > a:nth-child(1) {
      margin-bottom: 16px;
    }
  }
`;

export type ContentProps = {
  fixed: boolean;
};

const Content: React.FC<ContentProps> = ({ fixed = false }) => {
  const [active, setActive] = useState<boolean>(false);
  return (
    <StyledContent fixed={fixed}>
      <h2> Contact</h2>
      <p>
        I am currently looking for entry/junior position, if You would have any
        questions...
      </p>
      {!fixed && (
        <Buttons>
          <Button square primary icon="mail">
            Contact me
          </Button>
          <Button
            square
            arrow
            href={'#contact'}
            onClick={() => setActive(!active)}
          >
            Contact form
          </Button>
        </Buttons>
      )}
      <Form active={active} setActive={setActive} />
    </StyledContent>
  );
};

export default Content;
