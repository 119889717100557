import Button from 'components/Button';
import { Icon } from 'components/icons';
import Paragraph from 'components/Paragraph';
import { HeroProps } from 'components/types';
import Widgets from 'components/Widgets';
import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';

const Wrapper = styled.section`
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.laptop} {
    height: auto;
  }
`;

const Content = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 25px;
  align-items: center;
  max-width: 1000px;
  height: initial;
  background-color: #161a1d;
  border-radius: 16px;
  padding: 30px;

  & > svg {
    background-size: cover;
    border-radius: 5px;
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: 100px;
    filter: grayscale(100%);
    border: 1px solid var(--gray);
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    margin-top: 60px;
  }
`;

const Info = styled.div`
  max-width: 350px;
`;

const Tag = styled.div`
  display: inline-block;
  height: 24px;
  background-color: var(--orange);
  margin-bottom: 24px;
  padding: 4px 20px;
  border-radius: 4px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  & > * {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
`;

const Image = styled.img`
  height: auto;
  width: 100%;
  border-radius: 20px;

  @media ${device.tablet} {
    margin-top: 20px;
  }
`;

const Hero: React.FC<HeroProps> = ({ content }) => {
  const { tech, ghurl, url, name, description, quickDesc, img } = content;

  return (
    <Wrapper>
      <Content>
        <Info>
          <Tag>{quickDesc}</Tag>
          <Paragraph large header={name} text={description} />
          <ButtonsContainer>
            <Button square primary icon="external" href={url}>
              Live server
            </Button>
            <Button square icon="github" href={ghurl}>
              Source code
            </Button>
          </ButtonsContainer>
        </Info>
        {!img && <Icon name="logo" />}
        {img && <Image src={img} alt="" />}
      </Content>
      <Widgets list={tech} />
    </Wrapper>
  );
};

export default Hero;
