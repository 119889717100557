import Paragraph from 'components/Paragraph';
import SectionTitle from 'components/SectionTitle';
import { GoalsSectionProps } from 'components/types';
import SectionLayout from 'layout/Section';
import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';

const Grid = styled.ul`
  display: grid;

  @media ${device.tablet} {
    grid-template-rows: 1fr;
  }
`;

const Container = styled.li``;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 80%;
    width: auto;

    @media ${device.laptop} {
      height: 75%;
      width: 75%;
    }
  }
`;

const Goals: React.FC<GoalsSectionProps> = ({ content }) => {
  const { title, desc, paragraphs } = content;
  return (
    <SectionLayout id="Goals">
      <SectionTitle title={title} desc={desc} />
      <Grid>
        {paragraphs.map((paragraph, index) => (
          <Container key={index}>
            <ImageWrapper>
              <img src={paragraph.img} alt=""></img>
            </ImageWrapper>
            <Paragraph header={paragraph.header} text={paragraph.text} />
          </Container>
        ))}
      </Grid>
    </SectionLayout>
  );
};

export default Goals;
