const features = {
  title: 'Notable features',
  desc: 'Creme de la creme of the project.',
  paragraphs: [
    {
      icon: 'respons',
      header: 'Animations and responsiveness',
      text: 'An arcade look at farm life cycle simulation. Focused on object-oriented. An arcade look at farm life cycle simulation. Focused on object-oriented.An arcade look at farm life cycle simulation. Focused on object-oriented.',
    },
    {
      icon: 'hook',
      header: 'Hooks',
      text: 'An arcade look at farm life cycle simulation. Focused on object-oriented. An arcade look at farm life cycle simulation. Focused on object-oriented.An arcade look at farm life cycle simulation. Focused on object-oriented.',
    },
    {
      icon: 'observer',
      header: 'Intersection observer',
      text: 'An arcade look at farm life cycle simulation. Focused on object-oriented. An arcade look at farm life cycle simulation. Focused on object-oriented.An arcade look at farm life cycle simulation. Focused on object-oriented.',
    },
  ],
};

export default features;
