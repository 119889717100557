const tech = {
  title: 'Tech stack decisions',
  desc: '',
  paragraphs: [
    {
      header: 'Why TypeScript?',
      text: 'Mainly to push a little bit deeper in react syntax, giving the idea of how to React structure works, was especially handy while learning concepts around component props, and hooks. For example objects possibly undefined from returning array method, or the possibility of fetching data with an undefined return, makes catching errors a little bit less chaotic.',
    },
    {
      header: 'Styled-components ?',
      text: 'At first, I was looking for a way to have a single file for JSX, react syntax, and CSS. Along the way, I just loved the simplicity of using props for style modifications and alterations. It improved a lot of accessibility and readability of the code. ',
    },
    {
      header: 'To use, or not to use... libraries',
      text: 'As someone who tries to learn as much as I possibly can from the project, it was difficult to draw the invisible line between choosing a shortcut and reinventing the wheel for no reason. So for example with IntersectionObserver I used Scroll Reveal for animations and lazy images and made my way of tracking content on the project component to learn IO mechanics insights.',
    },
    {
      header: 'Was CSS a physical pain ?',
      text: 'No, it was a great way to explore how the browser works, what can be done with styling, and what are the works around some issues. CSS gave me instant visual gratification, which in terms of motivation helped immensely. I find a lot of similarities with street magicians to how CSS works under the hood, and how our perception as human beings works. It has some weird mechanics, but its effects can be magical.',
    },
    {
      header: 'How Figma saved my life ?',
      text: 'No, it was a great way to explore how the browser works, what can be done with styling, and what are the works around some issues. CSS gave me instant visual gratification, which in terms of motivation helped immensely. I find a lot of similarities with street magicians to how CSS works under the hood, and how our perception as human beings works. It has some weird mechanics, but its effects can be magical.',
    },
  ],
};

export default tech;
