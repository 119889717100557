const overview = {
  title: 'Overview',
  desc: 'A quick look at general information about the project with a brief description of the development process.',
  paragraphs: [
    {
      header: 'Project',
      text: 'The project is an arcade game that simulates the farm life cycle. Being familiar with computer games as a concept, I tried to use it as a framework to explore TypeScript in the Object-Oriented Programing approach, which is how the Phaser engine is implemented. I thought well-written documentation with a very willingly helpful community is a good way to dive into new ground.',
    },
    {
      header: 'Project difficulties',
      text: 'Most difficulties came up with JavaScript runtime limitation, movement based on event listeners, state machine shared across the scenes, and plant growing mechanism that works independently from what the character is doing.',
    },
    {
      header: 'Solutions',
      text: 'I extracted the movement of the character and NPCs into a separate class, which was run across scenes, and debounced a callback, with adjustment to the requestAnimationFrame loop. After exploring the concept of a state machine, I stored the state of the game inside an object and shared it across the scenes. The growing of the plants was based on the Date.now() method, which gave an impression of running processes simultaneously from the players standpoint.',
    },
  ],
  features: {
    title: 'Notable features',
    items: [
      'Using instance of a class for universal movement',
      'Implemented scenes with tale maps.',
      'State machine having in mind best practice from game devevelopment.',
      'Creating multiple NPCs with random paths.',
      'Shop scene with a dialog, enabling buying items.',
    ],
  },
};

export default overview;
