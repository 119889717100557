const IconPortfolio = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeWidth="2"
    viewBox="0 0 290.626 290.626"
    fill="currentColor"
  >
    <g>
      <g>
        <circle fill="none" cx="145.313" cy="173.438" r="84.375" />
        <path
          d="M276.563,65.625h-37.509c-0.042-6.08-3.947-11.213-9.366-13.172V42.347
			c0-18.178-14.794-32.972-32.972-32.972H94.003c-18.183,0-33.066,14.794-33.066,32.972V52.42
			c-4.688,1.936-9.323,7.092-9.366,13.205H14.063C6.309,65.625,0,71.935,0,79.688v187.5c0,7.753,6.309,14.063,14.063,14.063h262.5
			c7.753,0,14.063-6.309,14.063-14.063v-187.5C290.625,71.935,284.316,65.625,276.563,65.625z M98.428,65.625
			c-0.042-6.08-3.947-11.213-9.366-13.172v-6.609c0-4.598,3.745-8.344,8.344-8.344h95.813c4.598,0,8.344,3.745,8.344,8.344v6.605
			c-5.419,1.959-9.323,7.097-9.366,13.172H98.428V65.625z M206.344,60.938h18.563c2.606,0,4.73,2.095,4.781,4.688h-28.125
			C201.614,63.033,203.738,60.938,206.344,60.938z M70.313,42.347c0-13.013,10.678-23.597,23.691-23.597h102.713
			c13.013,0,23.597,10.584,23.597,23.597v9.216h-9.375v-5.719c0-9.769-7.95-17.719-17.719-17.719H97.406
			c-9.769,0-17.719,7.95-17.719,17.719v5.719h-9.375v-9.216H70.313z M65.719,60.938h18.563c2.606,0,4.73,2.095,4.781,4.688H60.938
			C60.989,63.033,63.113,60.938,65.719,60.938z M14.063,75h37.5h46.875h93.75h46.875h37.5c2.583,0,4.688,2.105,4.688,4.688v56.836
			l-52.172,6.956c-12.342-34.392-45.178-59.105-83.766-59.105s-71.424,24.712-83.766,59.105l-52.172-6.956V79.688
			C9.375,77.105,11.48,75,14.063,75z M194.893,168.75c-0.047-1.448-0.131-2.873-0.225-4.298c4.298-2.156,7.284-6.563,7.284-11.691
			c0-6.712-5.081-12.197-11.588-12.97c-0.9-3.089-1.894-6.028-2.991-8.813c5.85-3.15,11.334-7.017,16.43-11.475
			c12.113,13.13,19.847,30.291,20.958,49.247H194.893z M150,97.327c6.722,4.683,17.227,13.809,25.097,29.1
			c-7.931,3.202-16.397,5.03-25.097,5.536V97.327z M123.614,110.55c0,2.067-1.683,3.75-3.75,3.75s-3.75-1.683-3.75-3.75
			s1.683-3.75,3.75-3.75S123.614,108.483,123.614,110.55z M117.216,123.408c0.853,0.173,1.739,0.267,2.648,0.267
			c7.238,0,13.125-5.888,13.125-13.125c0-2.077-0.53-4.017-1.392-5.766c3.328-3.192,6.445-5.644,9.028-7.444v34.622
			c-8.7-0.506-17.161-2.334-25.092-5.531C116.063,125.405,116.649,124.411,117.216,123.408z M140.625,141.338v27.412h-35.489
			c0.45-12.239,2.658-23.517,6.595-33.731C120.849,138.732,130.603,140.817,140.625,141.338z M140.625,178.125v27.328
			c-0.342,0.019-0.68,0.052-1.022,0.075c-2.245-4.012-6.492-6.764-11.409-6.764c-6.792,0-12.328,5.203-12.994,11.817
			c-1.158,0.417-2.306,0.858-3.445,1.327c-3.609-9.319-6.056-20.55-6.558-33.778h35.428V178.125z M131.944,211.885
			c0,2.067-1.683,3.75-3.75,3.75s-3.75-1.683-3.75-3.75s1.683-3.75,3.75-3.75S131.944,209.817,131.944,211.885z M117.741,219.736
			c2.395,3.183,6.169,5.273,10.453,5.273c5.827,0,10.725-3.844,12.431-9.112v33.647c-6.684-4.673-17.081-13.842-24.942-29.02
			C116.367,220.252,117.052,219.989,117.741,219.736z M150,249.614v-34.669c8.672,0.516,17.109,2.419,25.059,5.625
			C167.185,235.908,156.732,245.002,150,249.614z M150,205.463v-27.338h35.428c-0.492,13.261-2.906,24.488-6.469,33.792
			C169.796,208.177,160.013,205.988,150,205.463z M150,168.75v-27.412c10.022-0.52,19.767-2.606,28.884-6.319
			c0.867,2.241,1.659,4.589,2.386,7.045c-3.356,2.381-5.569,6.277-5.569,10.692c0,5.991,4.059,11.002,9.553,12.567
			c0.075,1.13,0.112,2.273,0.155,3.422H150V168.75z M188.827,156.511c-2.067,0-3.75-1.683-3.75-3.75s1.683-3.75,3.75-3.75
			s3.75,1.683,3.75,3.75S190.899,156.511,188.827,156.511z M183.558,122.358c-6.089-11.962-13.641-20.555-20.302-26.494
			c12.694,2.939,24.23,8.888,33.806,17.1C192.853,116.578,188.344,119.728,183.558,122.358z M124.739,98.391
			c-1.148-0.464-2.386-0.722-3.67-0.839c2.091-0.67,4.233-1.214,6.394-1.711C126.567,96.638,125.667,97.491,124.739,98.391z
			 M109.336,118.313c-0.769,1.341-1.542,2.681-2.245,4.059c-4.791-2.63-9.309-5.789-13.528-9.408
			c4.936-4.233,10.402-7.838,16.27-10.772c-1.903,2.278-3.094,5.17-3.094,8.363C106.739,113.466,107.724,116.133,109.336,118.313z
			 M86.822,119.503c5.091,4.453,10.575,8.325,16.425,11.475c-4.514,11.452-7.017,24.084-7.481,37.772H65.864
			C66.975,149.794,74.705,132.633,86.822,119.503z M95.728,178.125c0.502,14.897,3.338,27.413,7.416,37.842
			c-5.85,3.159-11.363,6.956-16.383,11.339c-12.08-13.12-19.791-30.258-20.897-49.181H95.728z M107.03,224.625
			c6.042,11.85,13.556,20.428,20.198,26.348c-12.623-2.944-24.094-8.864-33.633-17.03
			C97.758,230.391,102.249,227.264,107.03,224.625z M183.596,224.625c4.762,2.616,9.258,5.723,13.444,9.309
			c-9.539,8.17-21.014,14.095-33.647,17.039C170.035,245.053,177.553,236.475,183.596,224.625z M203.869,227.297
			c-5.058-4.42-10.561-8.212-16.378-11.348c4.073-10.425,6.909-22.936,7.406-37.823h29.864
			C223.655,197.044,215.949,214.182,203.869,227.297z M276.563,271.875h-262.5c-2.583,0-4.688-2.105-4.688-4.688V145.978
			l49.444,6.595c-1.617,6.703-2.569,13.669-2.569,20.864c0,49.111,39.952,89.063,89.063,89.063s89.063-39.952,89.063-89.063
			c0-7.195-0.952-14.161-2.569-20.864l49.444-6.595v121.209C281.25,269.771,279.146,271.875,276.563,271.875z"
        />
      </g>
    </g>
  </svg>
);

export default IconPortfolio;
