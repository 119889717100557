import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components/macro';

const Item = styled.li`
  margin-bottom: 8px;

  p {
    font-size: 14px;
    line-height: 24px;
    transition: color 0.15s;
  }

  :hover {
    p {
      color: var(--white);
    }
  }
`;

export type ListItemProps = {
  name?: string;
  href?: string;
  section?: string;
};

const ListItem: React.FC<ListItemProps> = ({
  children,
  href,
  name,
  section,
}) => {
  return (
    <Item>
      {name && section && (
        <HashLink
          to={`/${name}/#${
            section.charAt(0).toLocaleUpperCase() + section.slice(1)
          }`}
        >
          <p>{section.charAt(0).toLocaleUpperCase() + section.slice(1)}</p>
        </HashLink>
      )}
      {!name && section && (
        <HashLink to={`/#${section}`}>
          <p>{section.charAt(0).toLocaleUpperCase() + section.slice(1)}</p>
        </HashLink>
      )}
      {href && <a href={href}>{children}</a>}
    </Item>
  );
};

export default ListItem;
