import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';
import { Icon } from 'components/icons';
import { SideBarProps } from 'components/types';
import Hamburger from './Hamburger';

const Wrapper = styled.div`
  position: relative;

  a {
    padding: var(--nav-logo-icons-padding);

    &:not(:last-of-type):after {
      content: '';
      position: absolute;
      width: 1px;
      margin-left: 10px;
      height: 100%;
      background-color: var(--white);
      opacity: 0.6;
    }

    svg {
      height: 14px;
      width: 18px;
    }

    &:hover {
      color: var(--orange);
    }

    @media ${device.tablet} {
      display: none;
    }
  }
`;

export const SideBar: React.FC<SideBarProps> = ({ content, state }) => {
  return (
    <Wrapper>
      {content.map((item, i) => (
        <a aria-label={item.name} key={i} href={item.url}>
          <Icon name={item.name} />
        </a>
      ))}
      <Hamburger state={state} />
    </Wrapper>
  );
};

export default SideBar;
