const LoaderImg = () => (
  <svg
    className="loader-image"
    width="66"
    height="63"
    viewBox="0 0 66 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.5 31.5C65.5 48.5992 50.9714 62.5 33 62.5C15.0286 62.5 0.5 48.5992 0.5 31.5C0.5 14.4008 15.0286 0.5 33 0.5C50.9714 0.5 65.5 14.4008 65.5 31.5Z"
      fill="#151619"
      stroke="#151619"
    />
    <circle cx="3" cy="32" r="2.5" stroke="#CC8500" />
    <path
      d="M35.5 5C35.5 6.38071 34.3807 7.5 33 7.5C31.6193 7.5 30.5 6.38071 30.5 5C30.5 3.61929 31.6193 2.5 33 2.5C34.3807 2.5 35.5 3.61929 35.5 5Z"
      stroke="#CC8500"
    />
    <circle cx="63" cy="32" r="2.5" stroke="#CC8500" />
    <circle cx="19.5" cy="45.5" r="2" stroke="#CC8500" />
    <circle cx="19.5" cy="15.5" r="2" stroke="#CC8500" />
    <circle cx="46.5" cy="45.5" r="2" stroke="#CC8500" />
    <circle cx="46.5" cy="15.5" r="2" stroke="#CC8500" />
    <path
      d="M35.5 59C35.5 60.3807 34.3807 61.5 33 61.5C31.6193 61.5 30.5 60.3807 30.5 59C30.5 57.6193 31.6193 56.5 33 56.5C34.3807 56.5 35.5 57.6193 35.5 59Z"
      stroke="#CC8500"
    />
    <path d="M3 32C33.1713 32 33.0001 32.4265 33.0001 59.2942" stroke="#CC8500" />
    <path d="M33 5C33 32 33 32 63 32" stroke="white" />
  </svg>
);

export default LoaderImg;
