import React from 'react';
import styled, { css } from 'styled-components/macro';
import device from 'styles/device';

import { ListProps } from './types';

const Container = styled.ul``;

const Item = styled.li``;

const Title = styled.h3``;

const Wrapper = styled.div<{
  single: boolean;
  large: boolean;
  widgets: boolean;
  right: boolean;
}>`
  ${props =>
    !props.widgets &&
    css`
      ${Container} {
        display: grid;
        grid-template-columns: repeat(${props.single ? '1' : '2'}, minmax(50px, 450px));
        column-gap: 5px;
        list-style: none;

        ${Item} {
          position: relative;
          padding-left: 20px;

          :before {
            content: '⊳';
            position: absolute;
            color: var(--orange);
            left: 0;
            top: 25%;
            font-size: var(--fs-sm);
            line-height: 12px;
          }

          p {
            color: #a0aaba;
          }
        }
      }
    `}

  ${props =>
    props.widgets &&
    css`
      ${Container} {
        display: flex;
        align-items: center;
        ${props.right ? 'justify-content: flex-end;' : ''}
        flex-wrap: wrap;
        gap: 10px;
        list-style: none;

        ${Item} {
          z-index: 1;
          padding: 8px 12px;
          background-color: #333333;
          border-radius: 10px;

          p {
            color: white;
          }
        }

        @media ${device.tablet} {
          justify-content: flex-start;
        }
      }
    `}

  ${props =>
    !props.large &&
    css`
      ${Title} {
        padding-bottom: 5px;
        font-size: 16px;
        line-height: 24px;
        @media ${device.laptop} {
          font-size: 14px;
          line-height: 22px;
        }
      }

      p {
        padding: 0px;
        font-size: 14px;
        line-height: 24px;

        @media ${device.laptop} {
          font-size: 13px;
          line-height: 24px;
        }
      }
    `}

  ${props =>
    props.large &&
    css`
      ${Title} {
        padding-bottom: 24px;
      }

      @media ${device.laptop} {
        font-size: 18px;
        line-height: 28px;
      }

      @media ${device.mobileL} {
        font-size: 16px;
        line-height: 24px;
      }
    `}
`;

const List: React.FC<ListProps> = ({
  large = false,
  single = false,
  widgets = false,
  right = false,
  title,
  list,
}) => {
  return (
    <Wrapper right={right} large={large} single={single} widgets={widgets}>
      {title && <Title>{title}</Title>}
      <Container>
        {list.map((item, index) => (
          <Item key={index}>
            <p>{item}</p>
          </Item>
        ))}
      </Container>
    </Wrapper>
  );
};

export default List;
