import React from 'react';
import styled from 'styled-components/macro';
import { AboutProps } from 'components/types';
import List from 'components/List';
import SectionLayout from 'layout/Section';
import device from 'styles/device';

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(280px, 1fr) 1fr 1fr;
  grid-template-rows: auto auto auto;
  column-gap: 10px;
  padding-top: 50px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

const Background = styled.div`
  padding-bottom: 10px;
  grid-column: span 2 / 4;

  a {
    color: white !important;
    &:hover {
      text-decoration: underline;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    font-size: var(--fs-xs);
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-column: 1;
  }
`;

const ListsContainer = styled.div`
  grid-row: span 2 / -1;

  & > :nth-of-type(1) {
    padding-bottom: 10px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-row: span 1 / 5;
  }
`;

const ParagraphsContainer = styled.div`
  grid-column: span 2 / 4;
  grid-row: span 2;
  color: #a0aaba;

  p {
    padding-bottom: 10px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-column: span 3 / 4;
  }
`;

const About: React.FC<AboutProps> = ({ content }) => {
  const { devskills, skills } = content.en;
  return (
    <SectionLayout id="about" w={900}>
      <h2>{content.en.title}</h2>
      <Grid>
        <ListsContainer>
          <List title="SKILLS" list={skills} />
          <List title="DEVELOPING SKILLS" list={devskills} />
        </ListsContainer>
        <Background>{content.en.background}</Background>
        <ParagraphsContainer>
          <p>{content.en.now}</p>
          <p>{content.en.next}</p>
        </ParagraphsContainer>
      </Grid>
    </SectionLayout>
  );
};

export default About;
