import AboutContent from 'content/about';
import React from 'react';
import styled from 'styled-components/macro';
import About from 'components/sections/Home/About';
import Contact from 'components/Contact';
import Header from 'components/Header';

import Hero from 'components/sections/Home/Hero';
import Projects from 'components/sections/Home/Projects';
import Footer from 'components/Footer';
import OtherProjects from 'components/sections/Home/OtherProjects';
import { HomeProps } from 'components/types';

const Content = styled.div`
  /* filter: blur(5px) brightness(0.7); */
  /* pointer-events: none; */
  /* user-select: none; */
`;

const Home: React.FC<HomeProps> = ({ content }) => {
  const { projects } = content;
  return (
    <>
      <Content>
        <Header content={projects} />
        <Hero />
        <About content={AboutContent} />
        <Projects content={projects} />
        <OtherProjects />
        <Contact />
        <Footer content={projects} />
      </Content>
    </>
  );
};

export default Home;
