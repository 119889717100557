import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';
import { Icon } from './icons';
import { ParagraphProps } from './types';

const Wrapper = styled.div<{ large: boolean }>`
  & > * {
    margin-bottom: 24px;
  }

  h3 {
    font-size: 32px;
    font-wight: 700;
    line-height: 36px;

    @media ${device.laptop} {
      font-size: 28px;
      line-height: 32px;
    }

    @media ${device.laptop} {
      font-size: 24px;
      font-height: 28px;
    }

    @media ${device.mobileL} {
      font-size: 20px;
      font-height: 24px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;

    @media ${device.laptop} {
      font-size: 18px;
      line-height: 28px;
    }

    @media ${device.mobileL} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
`;

const Paragraph: React.FC<ParagraphProps> = ({ large = false, header, text, icon }) => {
  return (
    <Wrapper large={large}>
      {icon && (
        <IconContainer>
          <Icon name={icon} />
        </IconContainer>
      )}
      {large ? <h2>{header}</h2> : <h3>{header}</h3>}
      <p>{text}</p>
    </Wrapper>
  );
};

export default Paragraph;
