import Paragraph from 'components/Paragraph';
import SectionTitle from 'components/SectionTitle';
import { TechstackSectionProps } from 'components/types';
import SectionLayout from 'layout/Section';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import device from 'styles/device';

const Grid = styled.div<{ length: number }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 150px;
  row-gap: 75px;

  & > :last-of-type {
    ${({ length }) =>
      length % 2 !== 0 &&
      css`
        grid-column: span 2;
        max-width: 565px;
        justify-self: center;

        @media ${device.tablet} {
          grid-column: unset;
          justify-self: unset;
        }
      `}
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const Techstack: React.FC<TechstackSectionProps> = ({ content }) => {
  const { title, desc, paragraphs } = content;

  return (
    <SectionLayout id="Tech">
      <SectionTitle title={title} desc={desc} />
      <Grid length={paragraphs.length}>
        {paragraphs.map((paragraph, index) => (
          <Paragraph
            key={index}
            header={paragraph.header}
            text={paragraph.text}
          />
        ))}
      </Grid>
    </SectionLayout>
  );
};

export default Techstack;
