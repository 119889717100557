import React from 'react';
import styled from 'styled-components/macro';
import device from 'styles/device';
import { SectionTitleProps } from '../types';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  text-align: center;

  h1 {
    margin-bottom: 20px;
  }

  p {
    color: var(--p-color);
    margin-bottom: 80px;

    @media ${device.tablet} {
      margin-bottom: 60px;
    }
  }
`;

const SectionTitle: React.FC<SectionTitleProps> = ({ title, desc }) => {
  return (
    <Wrapper>
      <Container>
        <h1>{title}</h1>
        <p>{desc}</p>
      </Container>
    </Wrapper>
  );
};

export default SectionTitle;
